import { Box, useToast, Td, Button, useDisclosure } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import Modal from "../components/admin/Modal";
import Fallback from "../components/common/ui/Fallback";
import { useNavigator } from "../hooks/useNavigator";
import { signup, getAllAdmin, updatePassword } from "../utils/api";
import CreateTable from "../components/common/Table/Table";
import Loading from "../components/common/ui/Loading";
import CreateModal from "../components/common/Modal/Modal";
import CreateInput from "../components/common/TextInputs/Input";
import useStore from "../utils/store";

const Admin = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const [adminList, setAdminList] = useState([]);
  const [update, setUpdate] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();
  const allAdmins = async () => {
    setLoading(true);
    const response = await getAllAdmin();
    if (response[0]) {
      setAdminList(response[1]);
    }
    setLoading(false);
  };

  const createAdmin = async (newAdmin) => {
    setLoading(true);
    if (user?.addAdmin) {
      const [success, data] = await signup(newAdmin);
      if (success) {
        toast({
          title: "Admin Created",
          description: "Admin Created Successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "Error Creating Admin",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
    setLoading(false);
  };
  const [navigate, user] = useNavigator(() => {});
  const changePassword = async () => {
    setLoading(true);
    if (user?.addAdmin && update !== "" && isLoggedIn && newPassword !== "") {
      const newData = {
        email: update,
        password: newPassword,
      };
      const [success, data] = await updatePassword(newData);
      if (success) {
        toast({
          title: "Password Changed",
          description: data.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        allAdmins();
        onModalClose();
      } else {
        toast({
          title: "Error",
          description: "Error Changing Password",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "Error",
        description:
          "You don't have permission to change password or password is empty",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (user?.getAdmin) {
      allAdmins();
    }
  }, []);

  if (loading) {
    return <Loading />;
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: ".5rem",
        padding: "1rem",
      }}
    >
      {user?.addAdmin && <Modal title={"Add Admin"} save={createAdmin} />}
      {user.getAdmin ? (
        <>
          <CreateTable
            data={adminList}
            head={["ID", "Email", "Password", "Change Password"]}
            rows={(row) => {
              return (
                <>
                  <Td>{row._id}</Td>
                  <Td>{row.email}</Td>
                  <Td>{row.password}</Td>
                  <Td>
                    <Button
                      colorScheme="blue"
                      variant="solid"
                      onClick={() => {
                        setUpdate(row.email);
                        onModalOpen();
                      }}
                    >
                      Change Password
                    </Button>
                  </Td>
                </>
              );
            }}
          />
          <CreateModal
            title={`Change Password of (${update})  `}
            isOpen={isModalOpen}
            onClose={onModalClose}
            create={changePassword}
            button1="Change Password"
          >
            <CreateInput
              name="New Password"
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </CreateModal>
        </>
      ) : (
        <Fallback />
      )}
    </Box>
  );
};

export default Admin;
