import { ChakraProvider } from "@chakra-ui/react";
import { Route, Routes } from "react-router-dom";
import "./App.css";

import Layout from "./components/common/ui/Layout";
import Admin from "./pages/Admin";
import Notification from "./pages/Notification";
import Banner from "./pages/Banner";
import Categories from "./pages/Categories";
import CreateSeries from "./pages/CreateSeries";
import Home from "./pages/Home";
import Live from "./pages/Live";
import Login from "./pages/Login";
import Podcasts from "./pages/Podcasts";
import Series from "./pages/Series";
import Users from "./pages/Users";
import Genres from "./pages/Genre";

function App() {
  return (
    <ChakraProvider>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/genres"
          element={
            <Layout>
              <Genres />
            </Layout>
          }
        />
        <Route
          path="/podcasts"
          element={
            <Layout>
              <Podcasts />
            </Layout>
          }
        />

        <Route
          path="/series"
          element={
            <Layout>
              <Series />
            </Layout>
          }
        />
        <Route
          path="/categories"
          element={
            <Layout>
              <Categories />
            </Layout>
          }
        />
        <Route
          path="/series/create"
          element={
            <Layout>
              <CreateSeries />
            </Layout>
          }
        />
        <Route
          path="/series/update/:id"
          element={
            <Layout>
              <CreateSeries update={true} />
            </Layout>
          }
        />
        <Route
          path="/banner"
          element={
            <Layout>
              <Banner />
            </Layout>
          }
        />
        <Route
          path="/users"
          element={
            <Layout>
              <Users />
            </Layout>
          }
        />
        <Route
          path="/live"
          element={
            <Layout>
              <Live />
            </Layout>
          }
        />
        <Route
          path="/admins"
          element={
            <Layout>
              <Admin />
            </Layout>
          }
        />
        <Route
          path="/notification"
          element={
            <Layout>
              <Notification />
            </Layout>
          }
        />
        <Route path="/login" element={<Login />} />
      </Routes>
    </ChakraProvider>
  );
}

export default App;
