import { Box, Text } from "@chakra-ui/react";
import React from "react";

import Loading from "../components/common/ui/Loading";
import Card from "../components/Dashboard/Card";
import { useNavigator } from "../hooks/useNavigator";
import { getDashboardData } from "../utils/api";

import { BiPodcast } from "react-icons/bi";
import {
  FcFilingCabinet,
  FcParallelTasks,
  FcStackOfPhotos,
  FcBusinessman,
  FcCamcorderPro,
} from "react-icons/fc";
import { GrMultiple } from "react-icons/gr";
const Home = () => {
  const [dashboardData, setDashboardData] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const getDashboardDataHandler = async () => {
    setLoading(true);
    const [success, data] = await getDashboardData();
    if (success) {
      setDashboardData(data);
    }
    setLoading(false);
  };

  const [navigate, user] = useNavigator(getDashboardDataHandler);

  if (loading) {
    return <Loading />;
  }
  return (
    <Box sx={{ p: "10" }}>
      <Text
        fontSize="4xl"
        fontWeight="bold"
        mb="5"
        sx={{
          padding: "1rem",
          display: "flex",
          alignItems: "center",
          gap: 2,
          borderBottom: "1px solid #eee",
        }}
      >
        Dashboard <FcFilingCabinet />
      </Text>
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          flexWrap: "wrap",
        }}
      >
        <Card
          icon={<BiPodcast size={24} />}
          color={"purple"}
          name="Podcasts"
          number={dashboardData?.podcasts}
        />
        <Card
          icon={<GrMultiple size={24} />}
          color={"pink"}
          name="Series"
          number={dashboardData?.series}
        />
        <Card
          icon={<FcBusinessman size={24} />}
          color="blue"
          name="Users"
          number={dashboardData?.users}
        />
        <Card
          icon={<FcStackOfPhotos size={24} />}
          color={"yellow"}
          name="Banners"
          number={dashboardData?.banners}
        />
        <Card
          icon={<FcParallelTasks size={24} />}
          color={"green"}
          name="Categories"
          number={dashboardData?.categories}
        />
        <Card
          icon={<FcCamcorderPro size={24} />}
          color={"red"}
          name="Live Videos"
          number={dashboardData?.livevideos}
        />
      </Box>
    </Box>
  );
};

export default Home;
