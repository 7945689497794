import { Box, Button, useDisclosure, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import CreateModal from "./common/Modal/Modal";
import CreateInput from "./common/TextInputs/Input";
import { addGenre, editGenre } from "../utils/api";

const GenreModal = ({ title, update, reload }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = React.useState(false);
  const [genre, setGenre] = React.useState({
    name: "",
  });
  const createGenre = async () => {
    if (genre.name === "") {
      toast({
        title: "Error",
        description: "Please enter name",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    setLoading(true);
    if (!update) {
      const [success, data] = await addGenre(genre);
      if (success) {
        toast({
          title: "Genre Added",
          description: "Genre Added Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        reload();
        onClose();
      } else {
        toast({
          title: "Error",
          description: "Genre Error",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } else {
      const [success, data] = await editGenre(update._id, genre);
      if (success) {
        toast({
          title: "Genre Updated",
          description: "Genre Updated Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        reload();
        onClose();
      } else {
        toast({
          title: "Error",
          description: "Genre Error",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    if (update) {
      setGenre({
        name: update.name,
      });
    }
  }, [update]);

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Button
        colorScheme="blue"
        variant="solid"
        onClick={onOpen}
        sx={{
          width: "100%",
        }}
      >
        {title}
      </Button>
      <CreateModal
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        create={createGenre}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <CreateInput
            name="Name"
            onChange={(e) => {
              setGenre({ ...genre, name: e.target.value });
            }}
            value={genre?.name}
          />
        </Box>
      </CreateModal>
    </Box>
  );
};

export default GenreModal;
