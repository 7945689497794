import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useStore from "../utils/store";

export const useNavigator = (fun) => {
  const isLoggedIn = useStore((state) => state.isLoggedIn);
  const user = useStore((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn && user === null) {
      navigate("/login");
    } else {
      fun();
    }
  }, [isLoggedIn]);

  return [navigate, user];
};
