import { FormControl, FormLabel, Select } from "@chakra-ui/react";
import React from "react";

const CreateSelect = ({ name, children, onChange, value }) => {
  return (
    <FormControl>
      <FormLabel>{name}</FormLabel>
      <Select placeholder={name} onChange={onChange} value={value}>
        {children}
      </Select>
    </FormControl>
  );
};

export default CreateSelect;
