import { FormControl, FormLabel, Input, Text } from "@chakra-ui/react";
import React from "react";

const File = ({ name, onChange }) => {
  return (
    <FormControl
      mt={4}
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Text
        sx={{
          fontWeight: "500",
        }}
      >
        {name}
      </Text>
      <FormLabel
        sx={{
          cursor: "pointer",
          color: "blue.500",
          fontWeight: "400",
          _hover: {
            color: "blue.700",
          },
        }}
      >
        Upload Image
      </FormLabel>
      <Input
        placeholder={name}
        type={"file"}
        sx={{
          display: "none",
        }}
        onChange={onChange}
      />
    </FormControl>
  );
};

export default File;
