import {
  Box,
  Button,
  Select,
  Td,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import useActions from "../../hooks/useActions";
import { deleteCategory } from "../../utils/api";
import useStore from "../../utils/store";
import DeleteModal from "../common/Modal/DeleteModal";
import CreateModal from "../common/Modal/Modal";
import CreateTable from "../common/Table/Table";

import Loading from "../common/ui/Loading";
import Table from "../podcasts/Table";
import TableSeries from "../series/Table";
import Modal from "./Modal";
import Podcast from "./Podcast";
import Position from "./Position";
import Remove from "./Remove";

const TableCategories = ({ categories, movie, series, reload }) => {
  const [loading, setLoading] = React.useState(false);

  const {
    isOpen: isOpen3,
    onOpen: onOpen3,
    onClose: onClose3,
  } = useDisclosure();

  const user = useStore((state) => state.user);

  const [lists, setLists] = React.useState([]);
  const [listsItem, setListsItem] = React.useState([]);
  useEffect(() => {
    const arr = [];
    lists.forEach((item) => {
      arr.push(item.item);
    });
    setListsItem(arr);
  }, [lists]);

  const [updateAction, deleteAction] = useActions();
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <CreateTable
        data={categories}
        head={["Name", "Position", "type", "Image-Type", "Action"]}
        rows={(item) => (
          <>
            <Td>{item.name}</Td>
            <Td>{categories.findIndex((i) => i._id === item._id) + 1}</Td>
            <Td>{item.type}</Td>
            <Td>{item.imageType}</Td>
            <Td>
              <Button
                variant={"solid"}
                colorScheme={"blue"}
                onClick={() => {
                  setLists(item.list);
                  onOpen3();
                }}
              >
                View
              </Button>
            </Td>
            <Td
              sx={{
                display: "flex",
                gap: 2,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
                {user?.updateCategory && (
                  <>
                    <Podcast
                      item={item}
                      movie={movie}
                      series={series}
                      reload={reload}
                      setLoading={setLoading}
                    />
                    <Remove
                      item={item}
                      series={series}
                      reload={reload}
                      setLoading={setLoading}
                    />
                    <Position
                      item={item}
                      reload={reload}
                      setLoading={setLoading}
                    />
                  </>
                )}
                {user?.deleteCategory && (
                  <DeleteModal
                    onClick={async () => {
                      setLoading(true);
                      await deleteAction(item._id, deleteCategory);
                      setLoading(false);
                    }}
                  />
                )}
                {user?.updateCategory && (
                  <Modal update={item} title="Edit Category" reload={reload} />
                )}
              </Box>
            </Td>
          </>
        )}
      />

      <CreateModal
        isOpen={isOpen3}
        onClose={() => {
          onClose3();
        }}
        title={"Lists"}
        table
      >
        {lists[0]?.kind === "series" ? (
          <TableSeries series={listsItem} />
        ) : (
          <Table allPodcasts={listsItem} />
        )}
      </CreateModal>
    </>
  );
};

export default TableCategories;
