import { Box, Text } from "@chakra-ui/react";
import React from "react";

const Fallback = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
      }}
    >
      <Text fontSize="2xl">You don't have permission to view this page</Text>
    </Box>
  );
};

export default Fallback;
