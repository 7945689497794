import { Checkbox, CheckboxGroup, FormLabel } from "@chakra-ui/react";
import React from "react";

const CheckboxInput = ({ name, onChange, isChecked, notification }) => {
  return (
    <CheckboxGroup>
      <Checkbox isChecked={isChecked} onChange={onChange}>
        {!notification && "Able to"} {name}
      </Checkbox>
    </CheckboxGroup>
  );
};

export default CheckboxInput;
