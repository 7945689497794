import { Button, Image, Td, useDisclosure, Box } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import useActions from "../../hooks/useActions";
import { deleteSeries } from "../../utils/api";
import useStore from "../../utils/store";

import DeleteModal from "../common/Modal/DeleteModal";
import CreateModal from "../common/Modal/Modal";
import CreateTable from "../common/Table/Table";
import Loading from "../common/ui/Loading";
import Table from "../podcasts/Table";

const TableSeries = ({ series, actions, reload }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isloading, setLoading] = React.useState(false);
  const [data, setData] = React.useState({});
  const user = useStore((state) => state.user);
  const actionsI =
    actions && (user?.updateSeries || user?.deleteSeries) ? "Actions" : false;
  const navigate = useNavigate();
  const [updateAction, deleteAction] = useActions();
  if (isloading) {
    return <Loading />;
  }

  return (
    <>
      <CreateTable
        searchby={["name", "language", "ageLimit", "rating"]}
        pagging={true}
        head={[
          actionsI,
          "Name",
          "Description",
          "Horizontal-Banner",
          "Vertical-Banner",

          "Age-Limit",
          "Rating",
          "Podcasts",

          "Genres",
        ]}
        rows={(row) => (
          <>
            {actions && (user?.updateSeries || user?.deleteSeries) && (
              <Td>
                <Box
                  sx={{
                    display: "flex",
                    gap: 2,
                    flexDirection: "column",
                  }}
                >
                  {user?.deleteSeries && (
                    <DeleteModal
                      onClick={async () => {
                        setLoading(true);

                        await deleteAction(row._id, deleteSeries);
                        reload();
                        setLoading(false);
                      }}
                    />
                  )}
                  {user?.updateSeries && (
                    <Button
                      colorScheme={"blue"}
                      onClick={() =>
                        navigate(`/series/update/${row._id}`, {
                          state: row,
                        })
                      }
                    >
                      Edit
                    </Button>
                  )}
                </Box>
              </Td>
            )}
            <Td>{row.name}</Td>
            <Td>{row.description.slice(0, 25)}...</Td>
            <Td>
              <Image
                src={row.horizontalBanner}
                alt="horizontal-banner"
                sx={{
                  width: "100px",
                  height: "100px",
                }}
                rounded="md"
              />
            </Td>
            <Td>
              <Image
                src={row.verticalBanner}
                alt="vertical-banner"
                sx={{
                  width: "100px",
                  height: "100px",
                }}
                rounded="md"
              />
            </Td>

            <Td>{row.ageLimit}</Td>
            <Td>{row.rating}</Td>
            <Td>
              <Button
                colorScheme="blue"
                variant="solid"
                onClick={() => {
                  setData(series.filter((item) => item._id === row._id)[0]);
                  onOpen();
                }}
              >
                View
              </Button>
            </Td>

            <Td>
              {row.genres.map((item, index) => {
                return <p key={index}>{item?.name}</p>;
              })}
            </Td>
          </>
        )}
        data={series}
      />{" "}
      <CreateModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setData({});
        }}
        table
        title="Podcasts"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Table allPodcasts={data?.podcast} />
        </Box>
      </CreateModal>
    </>
  );
};

export default TableSeries;
