import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import React from "react";

const CreateInput = ({ name, onChange, value, type }) => {
  return (
    <FormControl>
      <FormLabel>{name}</FormLabel>
      <Input
        required
        placeholder={name}
        onChange={onChange}
        value={value}
        type={type ? type : "text"}
      />
    </FormControl>
  );
};

export default CreateInput;
