import { Image, Td, Link } from "@chakra-ui/react";
import React from "react";
import useActions from "../../hooks/useActions";
import { deletePodcast, updatePodcast } from "../../utils/api";
import useStore from "../../utils/store";
import Buttons from "../common/Actions/Buttons";

import CreateTable from "../common/Table/Table";
import Loading from "../common/ui/Loading";

const Table = ({
  episeries,
  allPodcasts,
  series,
  actions,
  reload,
  updatedSeriesPodcast,
  deleteSeriesPodcast,
}) => {
  const user = useStore((state) => state.user);
  const [loading, setLoading] = React.useState(false);
  const actionsI =
    actions && (user?.updatePodcast || user?.deletePodcast) ? "Actions" : false;
  const [updateActionHandler, deleteAction] = useActions();
  if (loading) {
    return <Loading />;
  }
  console.log(allPodcasts);
  return (
    <CreateTable
      searchby={!episeries && ["name", "language", "ageLimit", "rating"]}
      pagging={episeries ? false : true}
      head={[
        actionsI,
        "Name",

        !episeries && "Description",
        "Url-type",
        "Url",
        "Horizontal-Banner",
        "Vertical-Banner",
        "Duration",
        !episeries && "Rating",
        !episeries && "Language",
        !episeries && "Age-Limit",
        !episeries && "Voice-Over Artist",
        !episeries && "Genre",
        !episeries && "Suppoting Voice-Over Artist",
      ]}
      data={allPodcasts}
      rows={(item) => {
        return (
          <>
            {actions && (user?.updatePodcast || user?.deletePodcast) && (
              <Td>
                <Buttons
                  episeries={episeries}
                  item={item}
                  update={async (data, id) => {
                    if (!series) {
                      setLoading(true);
                      await updateActionHandler(data, id, updatePodcast);
                      reload();
                      setLoading(false);
                    } else {
                      updatedSeriesPodcast(data, id);
                    }
                  }}
                  deletePodcast={async () => {
                    if (!series) {
                      setLoading(true);
                      await deleteAction(item._id, deletePodcast);
                      reload();
                      setLoading(false);
                    } else {
                      deleteSeriesPodcast(item._id);
                    }
                  }}
                />
              </Td>
            )}
            <Td>{item.name}</Td>
            {!episeries && <Td>{item.description.slice(0, 25)}...</Td>}
            <Td>{item.urlType}</Td>
            <Td>
              <Link
                href={item.url}
                isExternal
                sx={{
                  color: "blue.500",
                  textDecoration: "underline",
                }}
              >
                Link
              </Link>
            </Td>
            <Td>
              <Image
                src={item.horizontalBanner}
                alt="horizontal-banner"
                sx={{
                  width: "100px",
                  height: "100px",
                }}
                rounded="md"
              />
            </Td>
            <Td>
              <Image
                src={item.verticalBanner}
                alt="vertical-banner"
                sx={{
                  width: "100px",
                  height: "100px",
                }}
                rounded="md"
              />
            </Td>
            <Td>{item.duration}</Td>
            {!episeries && (
              <>
                {" "}
                <Td>{item.rating}</Td>
                <Td>{item.language}</Td>
                <Td>{item.ageLimit}</Td>
                <Td>
                  {item.cast.map((item, index) => {
                    return <p key={index}>{item.name}</p>;
                  })}
                </Td>
                <Td>
                  {item.genres.map((item, index) => {
                    return <p key={index}>{item?.name}</p>;
                  })}
                </Td>
                <Td>{item?.extraCast}</Td>
              </>
            )}
          </>
        );
      }}
    />
  );
};

export default Table;
