import { Button, useDisclosure, useToast } from "@chakra-ui/react";
import React from "react";
import { addPodcastToCategory, addSeriesToCategory } from "../../utils/api";
import CreateModal from "../common/Modal/Modal";
import CreateInput from "../common/TextInputs/Input";
import Select from "react-select";
const Podcast = ({ item, reload, movie, series, setLoading }) => {
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  const [newListId, setNewListId] = React.useState({});
  const toast = useToast();
  const [add, setAdd] = React.useState({
    name: "",
    data: {},
  });
  console.log(movie, item);
  return (
    <>
      {item.type === "Single" ? (
        <Button
          variant={"solid"}
          colorScheme={"blue"}
          onClick={() => {
            setAdd({
              name: "Add Podcast",
              data: item,
            });
            setNewListId({
              position: item?.list?.length + 1,
            });
            onOpen2();
          }}
        >
          Add Podcast
        </Button>
      ) : (
        <Button
          variant={"solid"}
          colorScheme={"blue"}
          onClick={() => {
            setAdd({
              name: "Add Series",
              data: item,
            });
            setNewListId({
              position: item?.list?.length + 1,
            });
            onOpen2();
          }}
        >
          Add Series
        </Button>
      )}
      <CreateModal
        isOpen={isOpen2}
        onClose={() => {
          setAdd({
            name: "",
            data: {},
          });
          setNewListId({});
          onClose2();
        }}
        title={add.name}
        create={async () => {
          setLoading(true);

          if (add.name === "Add Podcast") {
            const formData = new FormData();
            formData.append("podcastId", newListId.podcastId);
            formData.append("categoryId", add.data._id);
            formData.append(
              "position",
              newListId.position
                ? newListId.position
                : add?.data?.list?.length + 1
            );
            const [success, res] = await addPodcastToCategory(formData);
            if (success) {
              toast({
                title: "Podcast Added",
                description: "Podcast Added Successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              reload();
            } else {
              toast({
                title: "Error",
                description: "Error Adding Movie",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
          } else {
            const formData = new FormData();

            formData.append("seriesId", newListId.seriesId);
            formData.append("categoryId", add.data._id);
            formData.append("position", newListId.position);
            const [success, res] = await addSeriesToCategory(formData);
            if (success) {
              toast({
                title: "Series Added",
                description: "Series Added Successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              reload();
            } else {
              toast({
                title: "Error",
                description: "Error Adding Series",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
          }
          setLoading(false);
        }}
      >
        {" "}
        <Select
          placeholder={
            add.name === "Add Podcast" ? "Select Podcast" : "Select Series"
          }
          onChange={(e) => {
            setNewListId((prev) => ({
              ...prev,
              [add.name === "Add Podcast" ? "podcastId" : "seriesId"]: e.value,
            }));
          }}
          options={
            add.name === "Add Podcast"
              ? movie
                  ?.filter(
                    (item2) =>
                      add?.data?.list?.findIndex(
                        (item3) => item3.item._id === item2._id
                      ) === -1
                  )
                  ?.map((item) => ({
                    value: item._id,
                    label: item.name,
                  }))
              : series
                  ?.filter(
                    (item2) =>
                      add?.data?.list?.findIndex(
                        (item3) => item3.item._id === item2._id
                      ) === -1
                  )
                  ?.map((item) => ({
                    value: item._id,
                    label: item.name,
                  }))
          }
        />
        {/* <Select
       
          onChange={(e) => {
            setNewListId((prev) => ({
              ...prev,
             :
                e.target.value,
            }));
          }}
        >
          {add.name === "Add Podcast"
            ? movie?.map((item) => (
                <option value={item._id}>{item.name}</option>
              ))
            : series?.map((item) => (
                <option value={item._id}>{item.name}</option>
              ))}
        </Select> */}
        <CreateInput
          type={"number"}
          name={"Position"}
          value={newListId.position}
          onChange={(e) => {
            setNewListId((prev) => ({
              ...prev,
              position: e.target.value,
            }));
          }}
        />
      </CreateModal>
    </>
  );
};

export default Podcast;
