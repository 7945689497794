import { Box, Button, Td, useToast } from "@chakra-ui/react";
import React from "react";
import CreateTable from "../components/common/Table/Table";
import CreateInput from "../components/common/TextInputs/Input";
import Loading from "../components/common/ui/Loading";
import { useNavigator } from "../hooks/useNavigator";
import { addGenre, getAllGeneres } from "../utils/api";
import Fallback from "../components/common/ui/Fallback";
import GenreModal from "../components/GenreModal";

const Genres = () => {
  const [loading, setLoading] = React.useState(false);
  const [genres, setGenres] = React.useState([]);
  const [newGenre, setNewGenre] = React.useState("");
  const toast = useToast();
  const getAllGenresHandler = async () => {
    if (
      user?.getSeries ||
      user?.addSeries ||
      user?.getMovies ||
      user?.addMovies
    ) {
      const [success, data] = await getAllGeneres();
      if (success) {
        setGenres(data);
      } else {
        toast({
          title: "Error",
          description: data,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
    setLoading(false);
  };

  const addGenreHandler = async () => {
    setLoading(true);
    if (
      user?.getSeries ||
      user?.addSeries ||
      user?.getMovies ||
      user?.addMovies
    ) {
      if (newGenre !== "") {
        const [success, data] = await addGenre({
          name: newGenre,
        });
        if (success) {
          toast({
            title: "Genre Added",
            description: "Genre Added Successfully",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          getAllGenresHandler();
        } else {
          toast({
            title: "Error",
            description: data,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      }
    }
    setLoading(false);
  };

  const [navigate, user] = useNavigator(() => {
    if (user) {
      getAllGenresHandler();
    } else {
      navigate("/login");
    }
  });
  if (loading) {
    return <Loading />;
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: ".5rem",
        padding: "1rem",
      }}
    >
      {user?.getSeries ||
      user?.addSeries ||
      user?.getMovies ||
      user?.addMovies ? (
        <>
          {/* <Box
            sx={{
              display: "flex",
              gap: ".5rem",
              padding: "1rem",
              width: "50%",
              justifyContent: "space-between",

              flexDirection: "column",
            }}
            border="1px solid black"
            borderRadius="md"
          >
            <CreateInput
              name={"Add Genre"}
              onChange={(e) => setNewGenre(e.target.value)}
            />
            <Button
              colorScheme="teal"
              variant="outline"
              onClick={addGenreHandler}
            >
              Add
            </Button>
          </Box> */}
          <GenreModal title="Add Genre" reload={getAllGenresHandler} />
          <CreateTable
            data={genres}
            head={["Name", "id"]}
            rows={(row) => {
              return (
                <>
                  <Td>{row.name}</Td>
                  <Td>{row._id}</Td>
                  <Td>
                    <GenreModal
                      title="Edit"
                      update={row}
                      reload={getAllGenresHandler}
                    />
                  </Td>
                </>
              );
            }}
          />
        </>
      ) : (
        <Fallback />
      )}
    </Box>
  );
};

export default Genres;
