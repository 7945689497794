import { Box, Button, Select, useDisclosure, useToast } from "@chakra-ui/react";
import React from "react";
import CreateModal from "../common/Modal/Modal";
import CreateInput from "../common/TextInputs/Input";
import File from "../common/TextInputs/File";
import { addUser } from "../../utils/api";
import Loading from "../common/ui/Loading";
const Modal = ({ title, reload }) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selected, setSelected] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [user, setUser] = React.useState({});
  const createUserHandler = async () => {
    const formData = new FormData();
    formData.append("name", user.name);

    formData.append("email", user.email);

    formData.append("phoneNumber", user.phoneNumber);

    formData.append("dob", user.dob);

    if (user.image) {
      formData.append("image", user.image);
    } else {
      formData.append(
        "imageUrl",
        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
      );
    }

    formData.append("signupType", selected);

    setIsLoading(true);
    const [success, res] = await addUser(formData);
    if (success) {
      toast({
        title: "User Created",
        description: "User has been created successfully",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      reload();
    } else {
      toast({
        title: "Error",
        description: "Error creating user",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <Box>
      <Button colorScheme="blue" variant="solid" onClick={onOpen}>
        {title}
      </Button>
      <CreateModal
        isOpen={isOpen}
        onClose={onClose}
        title={title}
        create={createUserHandler}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <CreateInput
            name="Name"
            onChange={(e) => {
              setUser({ ...user, name: e.target.value });
            }}
          />
          <Select
            placeholder="Select SignUp Type"
            onChange={(e) => setSelected(e.target.value)}
          >
            <option value="PhoneNumber">PhoneNumber</option>
            <option value="Google">Google</option>
            <option value="Facebook">Facebook</option>
          </Select>
          {selected != "" && (
            <>
              <CreateInput
                name={`Enter email`}
                onChange={(e) => setUser({ ...user, email: e.target.value })}
                type={"email"}
              />
              <CreateInput
                name={`Enter Phone Number`}
                onChange={(e) =>
                  setUser({ ...user, phoneNumber: e.target.value })
                }
                type={"number"}
              />
              <CreateInput
                name={`Enter DOB`}
                onChange={(e) => setUser({ ...user, dob: e.target.value })}
                type={"name"}
              />
            </>
          )}

          <File
            name={"Pick Image"}
            onChange={(e) => setUser({ ...user, image: e.target.files[0] })}
          />
        </Box>
      </CreateModal>
    </Box>
  );
};

export default Modal;
