import { Box, Button } from "@chakra-ui/react";
import React from "react";

import Fallback from "../components/common/ui/Fallback";
import Loading from "../components/common/ui/Loading";
import TableSeries from "../components/series/Table";

import { useNavigator } from "../hooks/useNavigator";
import { getAllSeries } from "../utils/api";

const Series = () => {
  const [series, setSeries] = React.useState([]);

  const [loading, setLoading] = React.useState(true);

  const getAllSeriesHandler = async () => {
    if (user?.getSeries) {
      const [success, data] = await getAllSeries();
      if (success) {
        setSeries(data.reverse());
      }
    }
    setLoading(false);
  };
  const [navigate, user] = useNavigator(getAllSeriesHandler);
  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem",
          gap: 3,
        }}
      >
        {user?.addSeries && (
          <Button
            colorScheme="blue"
            variant="solid"
            onClick={() => {
              navigate("/series/create");
            }}
          >
            Create Series
          </Button>
        )}
        {user?.getSeries ? (
          <TableSeries series={series} actions reload={getAllSeriesHandler} />
        ) : (
          <Fallback />
        )}
      </Box>
    </>
  );
};

export default Series;
