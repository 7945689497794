import { Button, useDisclosure } from "@chakra-ui/react";
import React from "react";
import CreateModal from "./Modal";

const DeleteModal = ({ onClick }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <Button
        colorScheme="red"
        onClick={onOpen}
        sx={{
          width: "100%",
        }}
      >
        Delete
      </Button>
      <CreateModal
        title={"Are you sure "}
        isOpen={isOpen}
        button1={"Delete"}
        button1Color={"red"}
        onClose={onClose}
        create={onClick}
      ></CreateModal>
    </div>
  );
};

export default DeleteModal;
