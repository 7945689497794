import axios from "axios";

const URL = "https://api.tangentpodcast.online";

export const loginEmail = async (username, password) => {
  try {
    const response = await axios.post(`${URL}/admin/login`, {
      email: username,
      password: password,
    });
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};
export const getAllGeneres = async () => {
  try {
    const response = await axios.get(`${URL}/admin/genres`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};
export const editGenre = async (id, data) => {
  try {
    const response = await axios.put(`${URL}/admin/genre/${id}`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (e) {
    return [false, e];
  }
};
export const addGenre = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/genre`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};
export const signup = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/signup`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (error) {
    return [false, error];
  }
};

export const getDashboardData = async () => {
  try {
    const res = await axios.get(`${URL}/admin/dashboard`);
    if (res.status === 200) {
      return [true, res.data];
    }
    return [false, res.data];
  } catch (err) {
    return [false, err];
  }
};

export const getAllPodcasts = async () => {
  try {
    const response = await axios.get(`${URL}/admin/podcast`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const createNewPodcast = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/podcast`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const getAllSeries = async () => {
  try {
    const response = await axios.get(`${URL}/admin/series`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const createNewSeries = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/series`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const getAllCategories = async () => {
  try {
    const response = await axios.get(`${URL}/admin/category`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const createNewCategory = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/category`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const addSeriesToCategory = async (data) => {
  try {
    const config = {
      method: "put",
      url: `${URL}/admin/category/add-series`,
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await axios(config);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const addPodcastToCategory = async (data) => {
  try {
    const config = {
      method: "put",
      url: `${URL}/admin/category/add-podcast`,
      data: data,
      headers: {
        "Content-Type": "application/json",
      },
    };
    const response = await axios(config);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const getAllBanner = async () => {
  try {
    const response = await axios.get(`${URL}/admin/banners`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const createNewBanner = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/banner`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const getAllUsers = async () => {
  try {
    const response = await axios.get(`${URL}/admin/users`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const updatePodcast = async (data, id) => {
  try {
    const response = await axios.put(`${URL}/admin/podcast/${id}`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const deletePodcast = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/podcast/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const updateSeries = async (data, id) => {
  try {
    const response = await axios.put(`${URL}/admin/series/${id}`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const deleteSeries = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/series/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const updateCategory = async (data, id) => {
  try {
    const response = await axios.put(`${URL}/admin/category/${id}`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const deleteCategory = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/category/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const updateBanner = async (data, id) => {
  try {
    const response = await axios.put(`${URL}/admin/banner/${id}`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const deleteBanner = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/banner/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const addUser = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/user`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/user/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const updateUserName = async (data, id) => {
  try {
    const response = await axios.put(`${URL}/admin/user/${id}`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const updateUserImage = async (data, id) => {
  try {
    const response = await axios.put(`${URL}/admin/user-image/${id}`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const getAllLiveVideos = async () => {
  try {
    const response = await axios.get(`${URL}/admin/live-videos`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const createNewLiveVideo = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/live-video`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const updateLiveVideo = async (data, id) => {
  try {
    const response = await axios.put(`${URL}/admin/live-video/${id}`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const deleteLiveVideo = async (id) => {
  try {
    const response = await axios.delete(`${URL}/admin/live-video/${id}`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const getAllAdmin = async () => {
  try {
    const response = await axios.get(`${URL}/admin/get-all-admins`);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const addNotification = async (data) => {
  try {
    const response = await axios.post(`${URL}/notification/`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const CheckPassword = async (data) => {
  try {
    const response = await axios.post(`${URL}/admin/check-password`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const updatePassword = async (data) => {
  try {
    const response = await axios.put(`${URL}/admin/update-password`, data);
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const removeMoviefromCategory = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/category/remove-podcast`,
      data
    );
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const removeSeriesfromCategory = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/category/remove-series`,
      data
    );
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const updateSeriesPosition = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/category-update/series-position`,
      data
    );
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};

export const updatePodcastPosition = async (data) => {
  try {
    const response = await axios.put(
      `${URL}/admin/category-update/podcast-position`,
      data
    );
    if (response.status === 200) {
      return [true, response.data];
    }
    return [false, response.data];
  } catch (err) {
    return [false, err];
  }
};
