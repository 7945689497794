import { Button, Select, useDisclosure, useToast } from "@chakra-ui/react";
import React from "react";
import {
  removeMoviefromCategory,
  removeSeriesfromCategory,
} from "../../utils/api";
import CreateModal from "../common/Modal/Modal";

const Remove = ({ item, setLoading, reload }) => {
  const [remove, setRemove] = React.useState({});
  const [removeData, setRemoveData] = React.useState({});
  const {
    isOpen: isOpen4,
    onOpen: onOpen4,
    onClose: onClose4,
  } = useDisclosure();
  const toast = useToast();
  return (
    <>
      {item.type === "Single" ? (
        <Button
          variant={"solid"}
          colorScheme={"red"}
          onClick={() => {
            setRemove({
              name: "Podcast",
              data: item,
            });
            onOpen4();
          }}
        >
          Remove Podcast
        </Button>
      ) : (
        <Button
          variant={"solid"}
          colorScheme={"red"}
          onClick={() => {
            setRemove({
              name: "Series",
              data: item,
            });
            onOpen4();
          }}
        >
          Remove Series
        </Button>
      )}

      <CreateModal
        isOpen={isOpen4}
        onClose={() => {
          onClose4();
        }}
        title={"Remove"}
        create={async () => {
          setLoading(true);
          if (remove.name === "Podcast") {
            const [success, res] = await removeMoviefromCategory(removeData);
            console.log(res);
            console.log(remove);
            if (success) {
              toast({
                title: "Podcast Removed",
                description: "Podcast Removed Successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              reload();
            } else {
              toast({
                title: "Error",
                description: "Error Removing Movie",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
          }

          if (remove.name === "Series") {
            const [success, res] = await removeSeriesfromCategory(removeData);
            console.log(res);
            if (success) {
              toast({
                title: "Series Removed",
                description: "Series Removed Successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
              });
              reload();
            } else {
              toast({
                title: "Error",
                description: "Error Removing Series",
                status: "error",
                duration: 3000,
                isClosable: true,
              });
            }
          }
          setLoading(false);
        }}
      >
        <Select
          placeholder={"Select Data"}
          onChange={(e) => {
            setRemoveData((prev) => ({
              [remove.name === "Podcast" ? "podcastId" : "seriesId"]:
                e.target.value,

              categoryId: remove.data._id,
            }));
          }}
        >
          {remove?.data?.list?.map((item) => (
            <option value={item.item._id}>{item.item.name}</option>
          ))}
        </Select>
      </CreateModal>
    </>
  );
};

export default Remove;
