import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import React from "react";
import List from "./List";
import { BiPodcast } from "react-icons/bi";
import {
  FcFilingCabinet,
  FcParallelTasks,
  FcStackOfPhotos,
  FcBusinessman,
  FcCamcorderPro,
  FcMultipleInputs,
  FcAddressBook,
} from "react-icons/fc";
import { GrMultiple } from "react-icons/gr";
import { MdAdminPanelSettings } from "react-icons/md";
const Sidebar = ({ isOpen, onOpen, onClose }) => {
  return (
    <Box
      borderRight={{
        base: "none",
        md: "1px solid #eee",
      }}
      h="100%"
      sx={{
        "@media (max-width: 768px)": {
          display: "none",
        },
      }}
    >
      <List
        data={[
          { name: "DashBoard", icon: <FcFilingCabinet />, link: "/" },
          { name: "Podcasts", link: "/podcasts", icon: <BiPodcast /> },
          { name: "Series", link: "/series", icon: <GrMultiple /> },
          {
            name: "Categories",
            link: "/categories",
            icon: <FcParallelTasks />,
          },
          {
            name: "Banner",
            link: "/banner",
            icon: <FcStackOfPhotos />,
          },
          {
            name: "Users",
            link: "/users",
            icon: <FcBusinessman />,
          },
          {
            name: "Live Video",
            link: "/live",
            icon: <FcCamcorderPro />,
          },
          {
            name: "Admin",
            link: "/admins",
            icon: <MdAdminPanelSettings />,
          },
          {
            name: "Genres",
            link: "/genres",
            icon: <FcAddressBook />,
          },
          {
            name: "Notification",
            link: "/notification",
            icon: <FcMultipleInputs />,
          },
        ]}
      />
      <Drawer onClose={onClose} isOpen={isOpen} size={"sm"} placement={"left"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{`Tangent Podcast`}</DrawerHeader>
          <DrawerBody>
            <List
              data={[
                { name: "DashBoard", icon: <FcFilingCabinet />, link: "/" },
                { name: "Podcasts", link: "/podcasts", icon: <BiPodcast /> },
                { name: "Series", link: "/series", icon: <GrMultiple /> },
                {
                  name: "Categories",
                  link: "/categories",
                  icon: <FcParallelTasks />,
                },
                {
                  name: "Banner",
                  link: "/banner",
                  icon: <FcStackOfPhotos />,
                },
                {
                  name: "Users",
                  link: "/users",
                  icon: <FcBusinessman />,
                },
                {
                  name: "Live Video",
                  link: "/live",
                  icon: <FcCamcorderPro />,
                },
                {
                  name: "Admin",
                  link: "/admins",
                  icon: <MdAdminPanelSettings />,
                },
                {
                  name: "Notification",
                  link: "/notification",
                  icon: <FcMultipleInputs />,
                },
              ]}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
