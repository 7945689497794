import { Box, color, Text } from "@chakra-ui/react";
import React from "react";

const Card = ({ icon, bg, number, name, color }) => {
  return (
    <Box
      bg={color + ".100"}
      borderRadius="10px"
      sx={{
        padding: "1rem",
        margin: "1rem",
        display: "flex",
        flexDirection: "column",
        minWidth: "20rem",
        minHeight: "10rem",
        //media
        "@media screen and (max-width: 768px)": {
          minWidth: "15rem",
          minHeight: "8rem",
        },
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Text
          fontSize="2xl"
          fontWeight={"bold"}
          textTransform="uppercase"
          color={color + ".500"}
        >
          {name}
        </Text>
        {icon}
      </Box>
      <Text
        sx={{
          fontSize: "2.5rem",
          fontWeight: "bold",
        }}
      >
        {number}
      </Text>
    </Box>
  );
};

export default Card;
