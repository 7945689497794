import { Button, Select, useDisclosure, useToast } from "@chakra-ui/react";
import React from "react";
import { updatePodcastPosition, updateSeriesPosition } from "../../utils/api";
import CreateModal from "../common/Modal/Modal";
import CreateInput from "../common/TextInputs/Input";

const Position = ({ item, setLoading, reload }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [newPosition, setNewPosition] = React.useState({});
  const changePosition = async () => {
    setLoading(true);
    const data = {
      ...newPosition,
      categoryId: item._id,
    };
    if (item.type === "Single") {
      const [success, res] = await updatePodcastPosition(data);
      if (success)
        toast({
          title: "Podcast Position Changed",
          description: "Podcast Position Changed Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      else
        toast({
          title: "Podcast Position Changed",
          description: "Podcast Position Changed Successfully",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
    } else {
      const [success, res] = await updateSeriesPosition(data);
      if (success)
        toast({
          title: "Podcast Position Changed",
          description: "Podcast Position Changed Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      else
        toast({
          title: "Podcast Position Changed",
          description: "Podcast Position Changed Successfully",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
    }
    setLoading(false);
    reload();
  };
  return (
    <>
      <Button variant={"solid"} colorScheme={"blue"} onClick={onOpen}>
        Change Position
      </Button>
      <CreateModal
        isOpen={isOpen}
        onClose={onClose}
        title={"Change Position"}
        create={changePosition}
      >
        <Select
          placeholder={"Select Position"}
          onChange={(e) =>
            setNewPosition((prev) => ({
              ...prev,
              [item.type === "Single" ? "podcastId" : "seriesId"]:
                e.target.value,
              position:
                item?.list?.findIndex(
                  (item) => item?.item?._id === e.target.value
                ) + 1,
            }))
          }
        >
          {item?.list?.map((item, index) => {
            return (
              <option key={index} value={item.item._id}>
                {item.item.name}
              </option>
            );
          })}
        </Select>
        <CreateInput
          name={"Position"}
          type={"number"}
          onChange={(e) =>
            setNewPosition((prev) => ({ ...prev, position: e.target.value }))
          }
          value={newPosition?.position}
        />
      </CreateModal>
    </>
  );
};

export default Position;
