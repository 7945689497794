import { Box, useToast } from "@chakra-ui/react";
import React from "react";
import { createNewPodcast, getAllPodcasts } from "../utils/api";
import Loading from "../components/common/ui/Loading";
import { useNavigator } from "../hooks/useNavigator";
import Modal from "../components/podcasts/Modal";
import Table from "../components/podcasts/Table";
import Fallback from "../components/common/ui/Fallback";

const Podcasts = () => {
  const toast = useToast();
  const [allPodcasts, setAllPodcasts] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const getAllPodcastsHandler = async () => {
    if (user?.getPodcast) {
      const [success, data] = await getAllPodcasts();
      if (success) {
        console.log(data);
        setAllPodcasts(data.reverse());
      } else {
        toast({
          title: "Error",
          description: data,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    }
    setLoading(false);
  };
  const [navigate, user] = useNavigator(getAllPodcastsHandler);

  const createNewPodcastHandler = async (newPodcast, id) => {
    setLoading(true);
    if (user?.addPodcast) {
      const [success, res] = await createNewPodcast(newPodcast);
      if (success) {
        toast({
          title: "Podcast Created",
          description: "Podcast Created Successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        getAllPodcastsHandler();
      } else {
        toast({
          title: "Error",
          description: "Error Creating Podcast",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
    setLoading(false);
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        gap: "2rem",
      }}
    >
      {user?.addPodcast && (
        <Modal save={createNewPodcastHandler} title={"Add Podcast"} />
      )}
      {user?.getPodcast ? (
        <Table
          allPodcasts={allPodcasts}
          actions={true}
          reload={getAllPodcastsHandler}
        />
      ) : (
        <Fallback />
      )}
    </Box>
  );
};

export default Podcasts;
