import { Box, Button, Image, Td, useDisclosure } from "@chakra-ui/react";
import React from "react";
import CreateTable from "../components/common/Table/Table";
import Loading from "../components/common/ui/Loading";
import Modal from "../components/users/Modal";
import { useNavigator } from "../hooks/useNavigator";
import {
  deleteUser,
  getAllUsers,
  updateUserImage,
  updateUserName,
} from "../utils/api";
import useActions from "../hooks/useActions";
import CreateInput from "../components/common/TextInputs/Input";
import CreateModal from "../components/common/Modal/Modal";
import File from "../components/common/TextInputs/File";
import Fallback from "../components/common/ui/Fallback";
import DeleteModal from "../components/common/Modal/DeleteModal";
const Users = () => {
  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [newUser, setNewUser] = React.useState({});
  const {
    isOpen: isOpenModal1,
    onOpen: onOpenModal1,
    onClose: onCloseModal1,
  } = useDisclosure();
  const {
    isOpen: isOpenModal2,
    onOpen: onOpenModal2,
    onClose: onCloseModal2,
  } = useDisclosure();

  const getAllUsersHandler = async () => {
    if (user?.getUser) {
      const [success, data] = await getAllUsers();
      if (success) {
        setUsers(data.reverse());
      }
    }
    setLoading(false);
  };
  const [navigate, user] = useNavigator(getAllUsersHandler);
  const [updateAction, deleteAction] = useActions();

  if (loading) {
    return <Loading />;
  }
  return (
    <Box
      sx={{
        display: "flex",

        flexDirection: "column",
        padding: "1rem",
      }}
    >
      {user?.addUser && (
        <Modal title={" Create User"} reload={getAllUsersHandler} />
      )}
      {user?.getUser ? (
        <CreateTable
          searchby={["name", "email", "ageLimit"]}
          pagging={true}
          data={users}
          head={[
            "Profile",
            "Name",
            "Email",
            "Phone-Number",
            "DOB",
            "Signup-Method",
            "Actions",
          ]}
          rows={(row) => {
            return (
              <>
                <Td>
                  <Image src={row.image} rounded="full" boxSize="50px" />
                </Td>
                <Td>{row.name}</Td>
                <Td>{row.email}</Td>
                <Td>{row.phoneNumber}</Td>
                <Td>{row.dob}</Td>
                <Td>{row.signupType}</Td>
                <Td>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    {user?.updateUser && (
                      <>
                        {" "}
                        <Button
                          colorScheme="blue"
                          variant="solid"
                          onClick={() => {
                            setNewUser((prev) => ({
                              ...prev,
                              name: row.name,
                              email: row.email,
                              phoneNumber: row.phoneNumber,
                              id: row._id,
                            }));
                            onOpenModal1();
                          }}
                        >
                          Edit User
                        </Button>
                        <Button
                          colorScheme="blue"
                          variant="solid"
                          onClick={() => {
                            setNewUser((prev) => ({ ...prev, id: row._id }));
                            onOpenModal2();
                          }}
                        >
                          Edit Image
                        </Button>
                      </>
                    )}
                    {user?.deleteUser && (
                      <DeleteModal
                        onClick={async () => {
                          setLoading(true);
                          await deleteAction(row._id, deleteUser);
                          getAllUsersHandler();
                          setLoading(false);
                        }}
                      />
                    )}
                  </Box>
                </Td>
              </>
            );
          }}
        />
      ) : (
        <Fallback />
      )}

      <CreateModal
        title={"Edit Profile"}
        isOpen={isOpenModal1}
        onClose={onCloseModal1}
        create={async () => {
          setLoading(true);
          await updateAction(
            {
              name: newUser.name,
              email: newUser.email,
              phoneNumber: newUser.phoneNumber,
            },
            newUser.id,
            updateUserName
          );
          setNewUser({});
          getAllUsersHandler();
          setLoading(false);
        }}
      >
        <CreateInput
          name="Name"
          onChange={(e) => {
            setNewUser((prev) => ({ ...prev, name: e.target.value }));
          }}
          value={newUser.name}
        />
        <CreateInput
          name="Email"
          onChange={(e) => {
            setNewUser((prev) => ({ ...prev, email: e.target.value }));
          }}
          value={newUser.email}
        />
        <CreateInput
          name="Phone"
          onChange={(e) => {
            setNewUser((prev) => ({ ...prev, phoneNumber: e.target.value }));
          }}
          value={newUser.phoneNumber}
        />
      </CreateModal>

      <CreateModal
        title={"Edit Image"}
        isOpen={isOpenModal2}
        onClose={onCloseModal2}
        create={async () => {
          setLoading(true);
          const formData = new FormData();
          formData.append("image", newUser.image);
          await updateAction(formData, newUser.id, updateUserImage);
          setNewUser({});
          getAllUsersHandler();
          setLoading(false);
        }}
      >
        <File
          onChange={(e) =>
            setNewUser((prev) => ({ ...prev, image: e.target.files[0] }))
          }
          name="New Image"
        />
      </CreateModal>
    </Box>
  );
};

export default Users;
